import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/esm/Row';
import Modal from 'react-bootstrap/Modal';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledModal } from '../StyledModal/StyledModal';

interface IDoDoubleOptInViaLinkModal {
    show: boolean;
    onHide: () => void;
}

const DoDoubleOptInViaLinkModal = (props: IDoDoubleOptInViaLinkModal) => {
    return (
        <div>
            <StyledModal show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Aktivierung Ihres Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Alert variant="danger">
                            <p>
                                Um Ihren Account zu aktivieren, klicken Sie auf den Aktivierungslink den Sie per Mail
                                erhalten haben.
                            </p>
                        </Alert>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <div style={{ float: 'left' }}>
                            <StyledCol textAlign="left">
                                <StyledButton variant="primary" onClick={props.onHide}>
                                    OK
                                </StyledButton>
                            </StyledCol>
                        </div>
                    </Row>
                </Modal.Footer>
            </StyledModal>
        </div>
    );
};

export default DoDoubleOptInViaLinkModal;
